::-webkit-scrollbar {
  width: 6px !important;
}

/* this targets the default scrollbar (compulsory) */

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Hide Number Input Roller */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.23) !important;
  border-radius: 24px;
  transition: all 0.5s;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(146, 151, 165, 1) !important;
}

/* this will style the thumb, ignoring the track */

::-webkit-scrollbar-button {
  background-color: transparent !important;
}

/* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */

::-webkit-scrollbar-corner {
  background-color: transparent !important;
}

/* if both the vertical and the horizontal bars appear, then perhaps the right bottom corner also needs to be styled */
@font-face {
  font-family: "TDS_bold";
  src: url("./font/Poppins-Bold.ttf");
}

@font-face {
  font-family: "TDS_medium";
  src: url("./font/Poppins-Medium.ttf");
}

@font-face {
  font-family: "TDS_regular";
  src: url("./font/Poppins-Regular.ttf");
}

/* use this class to attach this font to any element i.e. <p class="fontsforweb_fontid_9785">Text with this font applied</p> */
html {
  height: 100%;

}

body {
  font-family: "TDS_bold" !important;
    margin: 0;
      padding: 0;
}

code {
  font-family: "TDS_bold" !important;
}

textarea:focus,
input:focus {
  font-family: "TDS_regular" !important;
}

input,
select,
textarea {
  font-family: "TDS_regular" !important;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  /* Light grey */
  border-top: 10px solid #383636;
  /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 90%;
  position: absolute;
  margin: 0%;
  width: 100%;
}